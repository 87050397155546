<template>
	<Header counter=1 />
	<div class="dash">
		<div class="row">
			<div class="col-md-3">
				<img alt="Vue logo" src="@/assets/onehealth.png" style="max-width:50%" >
			</div>
			<div class="col-md-9">
				<h1>One Health Event Based Surveillance System Dashboard</h1>
			</div>
		</div>
		<br>
		<div class="row">
			<p>One Health Event Based Surveillance Data Visualization
			(Management) Dashboard is a web based program aimed to help One Health stakeholders by improving the organization and access to local disease prevalence information. Timely and reliable disease information improves adequate recognition and reaction to high impact infections, such as emerging zoonosis, and promotes prevention and a progressive strategy to control. 
			</p>
		</div>
		<div class="row">
			<carousel :autoplay="4500" :wrap-around="true">
				<slide v-for="slide in img_array" :key="slide">
						<div class="col-md-9">
							<img :src="getImgUrl(slide.image)"/>
						</div>
						<div class="col-md-3">
							<p style="background:#cc99ff; padding:30px "><b>{{slide.text}}</b></p>
							<p style="background:#00cc99; padding:30px ">{{slide.des}}</p>
						</div>
				</slide>

				<template #addons>
					<navigation />
					<pagination />
				</template>
			</carousel>
		</div>
		<div class="row">
			<div class="col-md-6">
				<button type="button" class="btn btn-lg btn-block" :class="[{'btn-primary' :isEBS==1},{'btn-secondary' :isEBS!=1}]" @click="ebs">Event Based Surveillance</button>
			</div>
			<div class="col-md-6">
				<button type="button" class="btn btn-lg btn-block" :class="[{'btn-primary' :isEBS==2},{'btn-secondary' :isEBS!=2}]" @click="ibs">Indicator Based Surveillance</button>
			</div>
		</div><br/>
		<hr/>
		<div class="row" v-if="isEBS==1">
			<div class="col-md-6">
				<button type="button" class="btn btn-lg btn-block" :class="[{'btn-primary' :isArcEBS==1},{'btn-secondary' :isArcEBS!=1}]" @click="alterArcEbs">Archieved Events</button>
			</div>
			<div class="row" v-if="isArcEBS==1">
				<ArchievedEvents/>
			</div>
		</div>
		<div v-if="isEBS==0">
			<div class="col-md-9">
				<img src="@/assets/dashboard.png" >
				<div class="row">
					<div class="col-md-6">
						<h3>Pandemic disease</h3>
						<a href="http://103.247.238.92/webportal/pages/covid19.php" target="blank" class="btn btn-block btn-primary">Covid</a>
						<h3>Research &amp; publication</h3>
						<a href="https://drive.google.com/drive/folders/1wBO2en7M88HNBfQSH2VEBOR1XUXXSi3W?usp=sharing" target="blank" class="btn btn-block btn-primary">Articles</a>
					</div>
					<div class="col-md-6">
						<h3>PHEIC</h3>
						<a href="https://www.cdc.gov/poxvirus/monkeypox/response/2022/world-map.html" target="blank" class="btn btn-block btn-primary">Monkey pox</a>
						<h3>One Health Bangladesh Conference</h3>
						<a href="https://drive.google.com/drive/u/0/folders/1fxRdL3LpH9BT3TFrulMChPD2svTjWtPR" target="blank" class="btn btn-block btn-primary">Abstract Book</a>
					</div>
				</div>
			</div>
			<div class="col-md-3">
				<h3>Important links</h3>
				<br/>
				<a href="https://iedcr.gov.bd/" target="blank" class="btn btn-lg btn-block btn-primary">IEDCR</a>
				<a href="http://www.dls.gov.bd/" target="blank" class="btn btn-lg btn-block btn-primary">DLS</a>
				<a href="https://dghs.gov.bd/" target="blank" class="btn btn-lg btn-block btn-primary">DGHS</a>
				<a @click="is_bfd=!is_bfd" class="btn btn-lg btn-block btn-primary">BFD</a>
				<div v-if="is_bfd">
					<a href="http://www.bforest.gov.bd/" class="btn btn-lg btn-block btn-success" target="blank">BFD</a>
					<a href="" class="btn btn-lg btn-block btn-success">Anti Snake Venom Availability<i class="fas fa-dragon"></i></a>
					<a href="https://www.facebook.com/BangladeshBiodiversityConservationFederation/" target="blank" class="btn btn-lg btn-block btn-success">BBCF</a>
				</div>
				<a href="http://www.blri.gov.bd/" target="blank" class="btn btn-lg btn-block btn-primary">BLRI</a>
				<a href="https://onehealthbd.org/" target="blank" class="btn btn-lg btn-block btn-primary">OHS</a>
				<a href="https://www.who.int/" target="blank" class="btn btn-lg btn-block btn-primary">WHO</a>
				<a href="https://www.fao.org/home/en" target="blank" class="btn btn-lg btn-block btn-primary">FAO</a>
				<a href="https://www.oie.int/en/home/" target="blank" class="btn btn-lg btn-block btn-primary">OIE</a>
				<a href="https://www.cdc.gov/" target="blank" class="btn btn-lg btn-block btn-primary">US CDC</a>
				<a href="https://endingpandemics.org/" target="blank" class="btn btn-lg btn-block btn-primary">Ending Pandemics</a>
			</div>
		</div>
		<div v-if="isEBS==1 && isArcEBS!=1">
			<Outbreaks/>
		</div>
		<div v-if="isEBS==2">
			<div class="row">
				<h4>Please select topics to visualize in dashboard</h4>
				<div>
					<!-- <div class="form-check-inline">
						<label for="outbreak" class="form-check-label">
							<input type="radio" id="outbreak" name="d_cls" value="1" class="form-check-input" @change="onChange($event)" :checked="st_topic==1">Outbreak
						</label>
					</div> -->
					<div class="form-check-inline">
						<label for="Anthrax" class="form-check-label">
							<input type="radio" id="Anthrax" name="d_cls" value="1" class="form-check-input" @change="onChange($event)" :checked="st_topic==1">Anthrax
						</label>
					</div>
					<div class="form-check-inline">
						<label for="influenza" class="form-check-label">
							<input type="radio" id="influenza" name="d_cls" value="2" class="form-check-input" @change="onChange($event)" :checked="st_topic==2">Influenza
						</label>
					</div>
					<div class="form-check-inline">
						<label for="nipah" class="form-check-label">
							<input type="radio" id="nipah" name="d_cls" value="3" class="form-check-input" @change="onChange($event)" :checked="st_topic==3">Nipah
						</label>
					</div>
					<div class="form-check-inline">
						<label for="amr" class="form-check-label">
							<input type="radio" id="amr" name="d_cls" value="4" class="form-check-input" @change="onChange($event)">AMR
						</label>
					</div>
					<div class="form-check-inline">
						<label for="dengue" class="form-check-label">
							<input type="radio" id="dengue" name="d_cls" value="5" class="form-check-input" @change="onChange($event)" :checked="st_topic==5">Dengue
						</label>
					</div>
					<div class="form-check-inline">
						<label for="covid" class="form-check-label">
							<input type="radio" id="covid" name="d_cls" value="6" class="form-check-input" @change="onChange($event)">Covid
						</label>
					</div>
					<!-- <div class="form-check-inline">
						<label for="covid" class="form-check-label">
							<input type="radio" id="covid" name="d_cls" value="7" class="form-check-input" @change="onChange($event)" :checked="st_topic==7">Cholera
						</label>
					</div> -->
					<div class="form-check-inline">
						<label for="rabies" class="form-check-label">
							<input type="radio" id="rabies" name="d_cls" value="8" class="form-check-input" @change="onChange($event)" :checked="st_topic==8">Rabies
						</label>
					</div>
					<div class="form-check-inline">
						<label for="stopspill" class="form-check-label">
							<input type="radio" id="stopspill" name="d_cls" value="9" class="form-check-input" @change="onChange($event)" :checked="st_topic==9">Early event detection 
						</label>
					</div>
				</div>
			</div>
			<div v-if="st_topic==1">
				<Anthrax/>
			</div>
			<div v-if="st_topic==2">
				<Influenza />
				<!--<SubWeek />-->
				<!--<SubAge />-->
			</div>
			<div v-if="st_topic==3">
				<Nipah/>
			</div>
			<div v-if="st_topic==5">
				<Dengue/>
			</div>
			<div v-if="st_topic==7">
				<Cholera/>
			</div>
			<div v-if="st_topic==8">
				<Rabies/>
			</div>
		</div>
	</div>
	<hr/>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header'
import Influenza from '@/components/Influenza'
//import SubWeek from '@/components/SubWeek'
//import SubAge from '@/components/SubAge'
import Outbreaks from '@/components/outbreak/Outbreaks.vue'
import Nipah from '@/components/Nipah'
import Anthrax from '@/components/Anthrax'
import ArchievedEvents from '@/components/outbreak/ArchievedEvents'
import Dengue from '@/components/Dengue'
import Cholera from '@/components/Cholera'
import Rabies from '@/components/Rabies'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
export default {
  name: 'Dash',
  components: {
	Header,
	//SubWeek,
	//SubAge,
	Outbreaks,
	Nipah,
	Anthrax,
	Influenza,
	ArchievedEvents,
	Carousel,
	Slide,
	Pagination,
	Navigation,
	Dengue,
	Cholera,
	Rabies
  },
  data(){
	return{
		st_topic:1,
		isEBS:0,
		is_bfd:false,
		isArcEBS:0,
		img_array:[
			{
				image: 'slide1',
				text: 'Hit map of the distribution of outbreak',
				des: 'This hit map represents the distribution according to the district, Upazila, incident, and outbreak notification date.  This map is also updated automatically if any new event-based surveillance is reported'
			},{
				image: 'slide2',
				text: 'Influenza Data Trend',
				des: 'This data trend represents the distribution of monthly ILI & SARI cases'
			},{
				image: 'slide3',
				text: 'Global Circulation of Influenza Viruses',
				des: 'Flunet data visualization which reprented by National Influenza Centres (NICs) and other national influenza laboratories from 113 countries, areas or territories.'
			},{
				image: 'slide4',
				text: 'Division wise list of Events',
				des: 'Division wise number of outbreaks that occurred is shown in this bar diagram.'
			},{
				image: 'slide5',
				text: 'Percentage value of Bacteria susceptible to antibiotics',
				des: 'This image represents the percentage value of Bacteria Susceptible to antibiotics in selected specimen from selected sites for the selected organism.'
			},{
				image: 'slide6',
				text: 'Identified Infected Cases and Deaths from Nipah Virus',
				des: 'This image represents the year-wise Nipah identified infected cases and deaths'
			}
		]
	}
  },
  methods:{
	onChange(event){
		if(event.target.value == 4){
			window.location.href = 'https://dashboard.iedcr.gov.bd/amr/';
		}else if(event.target.value == 6){
			window.location.href = 'http://103.247.238.92/webportal/pages/covid19.php';
		}else if(event.target.value == 9){
			window.location.href = 'https://stopspilloverbangladesh.org/api_server_dashboard/';
		}else{
			this.st_topic = event.target.value;
		}
	},
	ebs(){
		if(this.isEBS!==1)
			this.isEBS=1;
		else
			this.isEBS=0;
	},
	ibs(){
		if (this.isEBS!=2) {this.isEBS=2;}
		else {this.isEBS=0;}
		
	},
	alterArcEbs(){
		if(this.isArcEBS ==1){
			this.isArcEBS =0;
		}else{
			this.isArcEBS =1;
		}
	},
	getImgUrl(pet) {
		var images = require.context('@/assets/', false, /\.png$/);
		return images('./' + pet + ".png");
	}
  }
}
</script>
<style scoped>
.carousel__item {
  height: 20px;
  
  width: 100%;
  background-color: #000066;
  color:  #3333ff;
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}
</style>
