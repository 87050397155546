<template>
	<div class="row">
		<div class="col-md-6">
			<button type="button" class="btn btn-lg btn-block" :class="[{'btn-primary' :is_human==1},{'btn-secondary' :is_human!=1}]" @click="is_human=1">Human Anthrax case</button>
		</div>
		<div class="col-md-6">
			<button type="button" class="btn btn-lg btn-block" :class="[{'btn-primary' :is_human==0},{'btn-secondary' :is_human!=0}]" @click="is_human=0">Animal Anthrax case</button>
		</div>
	</div>
	<div v-if="is_human==1">
		<h3>Anthrax ourbreaks visualization on map</h3>
		<iframe src="https://www.google.com/maps/d/embed?mid=16x2TdqtAUdzfIQwyNUtmbpF-SmTypBI&ehbc=2E312F" width="100%" height="500" ></iframe>
		<hr/>
		<p></p>
		<div class="row">
			<img src="https://resources.ohebsdd.org/anthrax/suspected_anthrax/anthrax_suspected.jpg" style="width: 100%;">
		</div>
		<hr/>
		<div class="row">
			<img src="https://resources.ohebsdd.org/anthrax/confirmed_anthrax/anthrax_confirmed.jpg" style="width: 100%;">
		</div>
	</div>
	<div v-if="is_human==0">
		<h3>Anthrax animal ourbreaks visualization on map</h3>
		<iframe src="https://www.google.com/maps/d/embed?mid=1dk8xqKCNWSj9L-U8I-PQU6rEwRmkcjE&ehbc=2E312F" width="100%" height="500" ></iframe>
		<hr/>
		<h3>Yearly district wise anthrax case distribution in animal</h3>
		<label class="form-control">Please select year
			<select v-model="year"  @change="getEventsYR">
				<option>2020</option>
				<option>2021</option>
			</select>
		</label>
		<img src="@/assets/loading.gif" width="200" v-if="is_loading">
		<DataTable :columns="columns" :rows="rows"/>
	</div>
</template>
<script>
import DataTable from '@/components/DataTable'
import axios from 'axios'
export default{
	name: 'Anthrax',
	components: {
		DataTable
    },
    data() {      
		return{
			is_human:1,
			year:2020,
			columns:[
				{
					label: 'Year',
					field: 'year'
				},
				{
					label: 'District',
					field: 'district'
				},
				{
					label: 'Case',
					field: 'frequency'
				}
			],
			rows:[],
			is_loading:false
		}
    },
    methods:{
		getEventsYR(){
			this.is_loading=true;
			axios.get("https://api.ohebsdd.org/dls_anthrax",{
				params:{yr: this.year}
			})
			.then((response) => {
				this.rows.splice(0);
				this.is_loading=false;
				if(response.data.data.length > 0){
					response.data.data.forEach((rd)=>{
						this.rows.push(rd);
					});
				}
			});
		}
	},
	mounted(){
		console.log("Mounted called");
		this.getEventsYR();	
	}
}
</script>