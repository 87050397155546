<template>
	<hr/>
	<h3>National Influenza Surveillance Bangladesh</h3>
	<br/>
	<div class="row">
		<table class="table">
			<tr>
				<td>
					<label>Select Month</label>
						<select v-model='mth'>
							<option value="1">January</option>
							<option value="2">February</option>
							<option value="3">March</option>
							<option value="4">April</option>
							<option value="5">May</option>
							<option value="6">June</option>
							<option value="7">July</option>
							<option value="8">August</option>
							<option value="9">September</option>
							<option value="10">October</option>
							<option value="11">November</option>
							<option value="12">December</option>
						</select>
				</td>
				<td>
					<label>Select Year</label>
					<select v-model='yr'>
						<option v-for="syr in yr_list" :key="syr.id">
							{{ syr }}
						</option>
					</select>
				</td>
				<td>
					<button class="btn btn-primary" @click="fetch_data">Submit</button>
				</td>
			</tr>
		</table>
		<WrongAlert :alerts="alerts"/>
		<img src="@/assets/loading.gif" width="200" v-if="is_loading"> <br/>
		<hr/>
		<div>
		<h3>Place distribution</h3>
		<div class="col-sm-12" v-if="sari.length>0">
			<p><strong>Sari</strong> case distribution</p>
			<table id='btable'>
				<tbody>
					<tr>
						<th>District</th>
						<th>AH1</th>
						<th>AH3</th>
						<th>AH1pdm09</th>
						<th>AH5</th>
						<th>AH3v</th>
						<th>AH7</th>
						<th>H1pdm09+H3</th>
						<th>H1pdm09+H3,VIC</th>
						<th>H1pdm09,VIC</th>
						<th>H3,Vic</th>
						<th>B/Vic</th>
						<th>B/Yama</th>
					</tr>
				
				
					<tr v-for="s in sari" :key="s.id">
						<td>{{s.place}}</td>
						<td>{{s.ah1}}</td>
						<td>{{s.ah3}}</td>
						<td>{{s.ah1pdm09}}</td>
						<td>{{s.ah5}}</td>
						<td>{{s.ah3v}}</td>
						<td>{{s.ah7}}</td>
						<td>{{s.h1pdm09h3}}</td>
						<td>{{s.h1pdm09h3vic}}</td>
						<td>{{s.h1pdm09vic}}</td>
						<td>{{s.h3vic}}</td>
						<td>{{s.bvic}}</td>
						<td>{{s.byama}}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="col-sm-12" v-if="ili.length>0">
			<p><strong>ILI</strong> case distribution</p>
			<table id='btable'>
				<tbody>
					<tr>
						<th>District</th>
						<th>AH1</th>
						<th>AH3</th>
						<th>AH1pdm09</th>
						<th>AH5</th>
						<th>AH3v</th>
						<th>AH7</th>
						<th>H1pdm09+H3</th>
						<th>H1pdm09+H3,VIC</th>
						<th>H1pdm09,VIC</th>
						<th>H3,Vic</th>
						<th>B/Vic</th>
						<th>B/Yama</th>
					</tr>
				
				
					<tr v-for="s in ili" :key="s.id">
						<td>{{s.place}}</td>
						<td>{{s.ah1}}</td>
						<td>{{s.ah3}}</td>
						<td>{{s.ah1pdm09}}</td>
						<td>{{s.ah5}}</td>
						<td>{{s.ah3v}}</td>
						<td>{{s.ah7}}</td>
						<td>{{s.h1pdm09h3}}</td>
						<td>{{s.h1pdm09h3vic}}</td>
						<td>{{s.h1pdm09vic}}</td>
						<td>{{s.h3vic}}</td>
						<td>{{s.bvic}}</td>
						<td>{{s.byama}}</td>
					</tr>
				</tbody>
			</table>
		</div>
		</div>
	</div>
	<div class="row">
		<h3>Influenza trends</h3>
		<div id="chart">
			<table class="table">
				<tr>
					<td>Select year</td>
					<td>
						<select class="form-control" @change="trendsyr($event)">
							<option value="">Please select year</option>
							<option v-for="syr in yr_list" :key="syr.id">
								{{ syr }}
							</option>
						</select>
					</td>
				</tr>
			</table>
			<img src="@/assets/loading.gif" width="200" v-if="is_loading"> <br/>
			<apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
		</div>
	</div>
	<div class="row">
		<h2>Influenza Seasonality graph</h2>
		<img src="https://resources.ohebsdd.org/influenza/seasonality_graph/influenza_seasonality_graph.jpg" style="width: 100%;">
	</div>
	<div class="row">
		<h2>National Influenza Surveillance, Bangladesh (NISB)</h2>
		<img src="https://resources.ohebsdd.org/influenza/nisb_seasonality_graph/nisb_influenza_seasonality_graph.jpg" style="width: 100%;">
	</div>
	<div class="row">
		<img src="https://resources.ohebsdd.org/influenza/flunet_global/global_circulation_influenza.jpg">
	</div>
</template>
<script>
import WrongAlert from '@/components/WrongAlert'
import VueApexCharts from 'vue3-apexcharts'
import axios from 'axios'
export default{
	name: 'Influenza',
	components:{
		WrongAlert,
		apexchart: VueApexCharts,
	},
	data(){
		return{
			mth:'',
			yr:'',
			yr_list:[],
			alerts:"Please select month and year to populate data",
			sari:[],
			ili:[],
			is_loading: false,
			sari_trend:[],
			ili_trend:[],
			trend_mth:[],
			series: [{
				name: "ILI",
				data: []
				},
				{
				name: "SARI",
				data: []
				},
			],
			chartOptions: {
				chart: {
						height: 350,
						type: 'line',
					zoom: {
						enabled: false
					}
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					curve: 'straight'
				},
				title: {
					text: 'Influenza trends by month',
					align: 'left',
				},
				grid: {
					row: {
					colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
					opacity: 0.5
					},
				},
				xaxis: {
					categories: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ],
				}
			},
		}
	},
	mounted(){
		var cur_yr = parseInt(new Date().getFullYear());
		while(cur_yr>=2017){
			this.yr_list.push(cur_yr);
			cur_yr--;
		}
	},
	methods:{
		fetch_data(){
			//console.log("Influenza fetch data clicked");
			this.is_loading=true;
			axios.get("https://api.ohebsdd.org/influenza",{
				params:{yr: this.yr, mth: this.mth}
			})
			.then((response) => {
				this.is_loading=false;
				this.sari = [];
				this.ili = [];
				if(response.data.data.length == 0){
					this.alerts = "No List to populate";
				}else{
					this.alerts = "";
					response.data.data.forEach((rd)=>{
						if(rd.type == 1){
							this.ili.push(rd);
						}else if(rd.type == 2){
							this.sari.push(rd);
						}
					});
				}

			});
		},
		trendsyr(event){
			var yrs = event.target.value;
			console.log();
			if(!yrs){
				return;
			}
			this.is_loading=true;
			axios.get("https://api.ohebsdd.org/influenza/"+yrs)
			.then((response)=>{
				//console.log(response);
				this.is_loading=false;
				this.sari_trend = [];
				this.ili_trend = [];
				this.trend_mth = [];
				this.chartOptions.xaxis.categories=[];
				var rd = response.data.data;
				rd.forEach((sc)=>{
					if(sc.type==1){	//ili
						this.ili_trend.push(sc.total);
						this.trend_mth.push(sc.month);
					}else if(sc.type==2){
						this.sari_trend.push(sc.total);
					}
				});
				this.series[0].data = this.ili_trend;
				this.series[1].data = this.sari_trend;
				this.trend_mth.forEach((int_month)=>{
					if(int_month==1){
						this.chartOptions.xaxis.categories.push("January");
					}else if(int_month==2){
						this.chartOptions.xaxis.categories.push("February");
					}else if(int_month==3){
						this.chartOptions.xaxis.categories.push("March");
					}else if(int_month==4){
						this.chartOptions.xaxis.categories.push("April");
					}else if(int_month==5){
						this.chartOptions.xaxis.categories.push("May");
					}else if(int_month==6){
						this.chartOptions.xaxis.categories.push("June");
					}else if(int_month==7){
						this.chartOptions.xaxis.categories.push("July");
					}else if(int_month==8){
						this.chartOptions.xaxis.categories.push("August");
					}else if(int_month==9){
						this.chartOptions.xaxis.categories.push("September");
					}else if(int_month==10){
						this.chartOptions.xaxis.categories.push("October");
					}else if(int_month==11){
						this.chartOptions.xaxis.categories.push("November");
					}else if(int_month==12){
						this.chartOptions.xaxis.categories.push("December");
					}
				});
			})
		}
	}
}
</script>
<style scoped>
#btable table,#btable th,#btable td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 3px;
}
</style>