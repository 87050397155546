<template>
	<h3>Timeliness Metrics</h3>
	<div class="col-md-12">
		<center><h1>{{outbreak.length}}</h1></center>
		<center><p>Total number of outbreak</p></center>
	</div>
	<hr/>
	<div class="row">
		<div class="col-md-3">
			<p>Time of detection (days)</p>
			<center><h1>{{dfstnot.toFixed(2)}}</h1></center>
		</div>
		<div class="col-md-3">
			<p>Time to verify (days)</p>
			<center><h1>{{dfnotvrf.toFixed(2)}}</h1></center>
		</div>
		<div class="col-md-3">
			<p>Time to respond (days)</p>
			<center><h1>{{dfcominv.toFixed(2)}}</h1></center>
		</div>
	</div>
	<hr/>
	<div class="row">
		<div class="col-md-6">
			<h3>Origin</h3>
			<donut_chart :series="origin_series" :values="origin_labels"/>
		</div>
		<div class="col-md-6">
			<h3>Human confirmed disease</h3>
			<donut_chart :series="hu_con_dis_series" :values="hu_con_dis_labels"/>
		</div>
	</div>
	<hr/>
	<table class="table">
		<thead>
			<td>Start Day</td>
			<td>Notification Day</td>
			<td>Verification Start</td>
			<td>Investigation Start</td>
			<td>Investigation End</td>
			<td>Human confirmed disease</td>
			<td>Animal confirmed disease</td>
			<td>Outbreak origin</td>
		</thead>
		<tbody>
			<tr v-for="ot in outbreak" :key="ot.id">
				<td>{{changeformat(ot.ev_start)}}</td>
				<td>{{changeformat(ot.ev_notify)}}</td>
				<td>{{changeformat(ot.ve_st)}}</td>
				<td>{{changeformat(ot.inv_st)}}</td>
				<td>{{changeformat(ot.inv_ed)}}</td>
				<td>{{ot.hu_con_dis}}</td>
				<td>{{ot.an_con_dis}}</td>
				<td>{{ot.origin}}</td>
			</tr>
		</tbody>
	</table>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import donut_chart from '../Donut_chart'
export default{
	name:'TimeMetrics',
	components: {
		donut_chart
    },
	data(){
		return{
			is_loading: false,
			api:"https://api.ohebsdd.org/time_metric",
			outbreak:[],
			dfstnot:0,
			dfnotvrf:0,
			dfcominv:0,
			origin_series:[],
			origin_labels:[],
			hu_con_dis_series:[],
			hu_con_dis_labels:[],
		}
	},
	methods:{
		changeformat(value){
			return moment(String(value)).format('DD/MM/YYYY');
		},
		datediff(v1,v2){
			console.log("v1:"+v1+" v2:"+v2);
			
			return moment(moment(String(v1)).subtract(moment(String(v2)))).format('DD');
			//moment(moment(String(v1)).subtract(moment(String(v2)))).day();
		}
	},
	mounted(){
		this.is_loading=true;
		axios
		.get(this.api,{
			headers: {
			'Authorization': localStorage.token
			}
		})
		.then((response)=>{
			this.is_loading=false;
			// console.log(response);
			this.outbreak = response.data.data;
			var stnot=0;
			var notvrf=0;
			var cominv =0;
			var origin_arr = [];
			var hu_con_dis_arr = [];
			this.outbreak.forEach((ot)=>{
				
				if(ot.ev_start!=null && ot.ev_start!="0000-00-00"&& ot.ev_notify !=null && ot.ev_notify!="0000-00-00"){
					this.dfstnot += parseInt(this.datediff(ot.ev_notify,ot.ev_start));
					stnot++;
				}
				if(ot.ev_notify!=null && ot.ev_notify!="0000-00-00"&& ot.ve_st !=null && ot.ve_st!="0000-00-00"){
					this.dfnotvrf += parseInt(this.datediff(ot.ve_st,ot.ev_notify));
					notvrf++;
				}
				if(ot.ev_notify !=null && ot.ev_notify!="0000-00-00" && ot.inv_ed!=null && ot.inv_ed!="0000-00-00"){
					this.dfcominv += parseInt(this.datediff(ot.inv_ed,ot.ev_notify));
					cominv++;
				}
				
				if(ot.origin!=null && ot.origin!=''){
					var is_found=0;
					origin_arr.forEach((oga)=>{
						if(oga.name == ot.origin){
							oga.value++;
							is_found=1;
						}
					});
					if (is_found==0) {
						origin_arr.push({
							name: ot.origin,
							value :1
						});
					}
				}
				if(ot.hu_con_dis!=null && ot.hu_con_dis!=''){
					is_found=0;
					hu_con_dis_arr.forEach((oga)=>{
						if(oga.name == ot.hu_con_dis){
							oga.value++;
							is_found=1;
						}
					});
					if (is_found==0) {
						hu_con_dis_arr.push({
							name: ot.hu_con_dis,
							value :1
						});
					}
				}
			});
			origin_arr.forEach((oga)=>{
				this.origin_series.push(oga.value);
				this.origin_labels.push(oga.name);
			});
			hu_con_dis_arr.forEach((oga)=>{
				this.hu_con_dis_series.push(oga.value);
				this.hu_con_dis_labels.push(oga.name);
			});
			this.dfstnot = this.dfstnot/stnot;

			this.dfnotvrf = this.dfnotvrf/notvrf;
			this.dfcominv = this.dfcominv/cominv;
		})
		.catch((err)=>{
			this.is_loading=false;
			console.log(err);
		})
	}
}	
</script>