<template>

<div v-bind:class="[{main_active:isBaropen},{main:!isBaropen}]">
  <button class="openbtn" @click="openNav">☰ Open Sidebar</button>  
</div>

<!-- <div class="sidenav"> -->
<div v-bind:class="[{sidenav_active:isBaropen},{sidenav:!isBaropen}]">
	<a class="closebtn" @click="closeNav">×</a>
	<div v-for="side in sidenav_num" :key="side">
		<a @click='dup' v-if="side==70">Data Upload</a>
		<a @click='dvd' v-if="side==20">Data Validation</a>
		<a @click='um' v-if="side==5">User management</a>
		<a @click='vp' v-if="side==6">Dashboard management</a>
		<a @click='ec' v-if="side==1" 
		:style="[{'color: #f1f1f1' :nav_index==1},{'color: #f1f1f1' :nav_index==11}]">Event capture</a>
		<a @click='ev' v-if="side==2">Event verification</a>
		<a @click='ir' v-if="side==4">List of pre &amp; post investigation report </a>    <!-- Event investigation -->
		<a @click='ra' v-if="side==3">Verified event investigation list</a>		<!-- Prior to event investigation -->
		<a @click='vipe' v-if="side==7">Visual permission</a>
		<a @click='cn' v-if="side==8">Internal Dashboard</a>
		<a @click='bfdtemp' v-if="side==10">Template</a>
		<a @click='tm' v-if="side==9">Timeliness metrics</a>
		<a @click='hisp' v-if="side==11">Human ili symptoms report</a>
		<a @click='wb' v-if="side==12">Wild Bird</a>
		<a @click='pb' v-if="side==13">Pet Bird</a>
	</div>
	<a @click='logout' >Logout</a>
</div>
</template>
<script >
	export default{
		name:'SideNav',
		props:['sidenav_num','nav_index'],
		data(){
			return{
				isBaropen:false
			}
		},
		methods:{
			dup(){
				this.$emit('navto',70);
			},
			dvd(){
				this.$emit('navto',20);	
			},
			um(){
				this.$emit('navto',5);	
			},
			vp(){
				this.$emit('navto',30);
			},
			ec(){
				this.$emit('navto',1);
			},
			ev(){
				this.$emit('navto',2);
			},
			ir(){
				this.$emit('navto',4);
			},
			ra(){
				this.$emit('navto',3);
			},
			cn(){
				this.$emit('navto',8);
			},
			tm(){
				this.$emit('navto',9);
			},
			vipe(){
				this.$emit('navto',7);
			},
			logout(){
				this.$emit('navto',0);
			},
			bfdtemp(){
				this.$emit('navto',101);
			},
			openNav(){
				console.log("Nav Opening");
				this.isBaropen = true;
			},
			hisp(){
				window.location.href = 'https://stopspilloverbangladesh.org/api_server_dashboard/data_list_show.php?cat=human&key=TwiSTisholUeRSadoEsEtAloGrEs';
			},
			wb(){
				window.location.href = 'https://stopspilloverbangladesh.org/api_server_dashboard/data_list_show.php?cat=wild_bird&key= XiCkEsNErVIVIrAMbesoNaCaraGE';
			},
			pb(){
				window.location.href = 'https://stopspilloverbangladesh.org/api_server_dashboard/data_list_show.php?cat=pet_bird&key= XiCkEsNErVIVIrAMbesoNaCaraGE';
			},
			closeNav(){
				console.log("Nav Closing");
				this.isBaropen = false;	
			}
		}
	}
</script>
<style scoped>
.sidenav {
  height: 100%;
  width:0px;	
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav_active a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}
.active {
  color: #f1f1f1;
}

.sidenav_active a:hover {
  color: #f1f1f1;
}
.main {
  transition: margin-left .5s;
  padding: 16px;
  marginLeft : 0px;
}
.sidenav_active {
  height: 100%;
  width:250px;	
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}
.main_active {
  transition: margin-left .5s;
  padding: 16px;
  marginLeft : 250px;
}
</style>