<template>
	<hr/>
	<h3>Countrywide Outbreaks</h3>
	<div class="row">
		<div class="col-md-3"><p>Filter according to year</p></div>
		<div class="col-md-9">
			<select class="form-control" v-model="year" @change="onChange">
				<option value="0">All</option>
				<option v-for="yr in year_list" :key="yr">{{yr}}</option>
				
			</select>
		</div>
	</div>
	<div id="chartdiv" ></div>
	<hr/>
	<div class="row">
		<h3>Most recent events</h3>
		<table class="table">
			<thead>
				<th>District</th>
				<th>Upazila</th>
				<th>Incident</th>
				<th>Notification date</th>
			</thead>
			<tbody>
				<tr v-for="d in recent_notifications" :key="d.id">
					<td>{{d.s_dis}}</td>
					<td>{{d.s_upz}}</td>
					<td>{{d.title}}</td>
					<td>{{changeformat(d.notification_date)}}</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_bangladesh from "@amcharts/amcharts4-geodata/bangladeshHigh";
import axios from 'axios';
import moment from 'moment'
export default{
	name:'BDMap',
	data(){
		return{
			year:0,
			year_list:[],
			recent_notifications:[],
			all_data:[],
			pdata : [{
			"id": "BD-A",
			"name": "Barisal",
			"value": 0
			// "fill": am4core.color("#ff8000")
			}, {
				"id": "BD-B",
				"name": "Chattogram",
				"value": 0
				// "fill": am4core.color("#ff6a00")
			}, {
				"id": "BD-C",
				"name": "Dhaka",
				"value": 0
				// "fill": am4core.color("#ff1100")
			}, {
				"id": "BD-D",
				"name": "Khulna",
				"value": 0
				// "fill": am4core.color("#ff8000")
			}, {
				"id": "BD-E",
				"name": "Rajshahi",
				"value": 0
				// "fill": am4core.color("#ff4400")
			}, {
				"id": "BD-F",
				"name": "Rangpur",
				"value": 0
				// "fill": am4core.color("#ffbb00")
			}, {
				"id": "BD-G",
				"name": "Shylet",
				"value": 0
				// "fill": am4core.color("#ffbb00")
			}, {
				"id": "BD-H",
				"name": "Mymensingh",
				"value": 0
				// "fill": am4core.color("#eaff00")
			}]
		}
	},
	mounted(){
		this.crt_chart(this.pdata);
		this.getEventList();
	},
	unmounted() {
		if (this.chart) {
			this.chart.dispose();
		}
	},
	methods:{
		crt_chart(ppdata){
			var chart = am4core.create("chartdiv", am4maps.MapChart);
			chart.geodata = am4geodata_bangladesh;
			chart.projection = new am4maps.projections.Projection();
			var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
			polygonSeries.useGeodata = true;
			polygonSeries.data = ppdata;
			var polygonTemplate = polygonSeries.mapPolygons.template;
			polygonTemplate.tooltipText = "{name} : {value}";
			var m = this;
			var all_data_collection = this.all_data;
			polygonTemplate.events.on("hit", function(ev) {
				console.log(ev.target.dataItem.dataContext.name);
				m.$emit('polyClicked',ev.target.dataItem.dataContext.name,all_data_collection);
				// custom_emit(ev.target.dataItem.dataContext.name);
				
			});
			var hs = polygonTemplate.states.create("hover");
			hs.properties.fill = am4core.color("#001eff");
			polygonSeries.heatRules.push({
				"property": "fill",
				"target": polygonSeries.mapPolygons.template,
				"max": am4core.color("#ff0000"),
				"min": am4core.color("#00ff1a")
			});
			let heatLegend = chart.createChild(am4maps.HeatLegend);
			heatLegend.series = polygonSeries;
			heatLegend.width = am4core.percent(100);
			polygonSeries.mapPolygons.template.events.on("over", function(ev) {
				if (!isNaN(ev.target.dataItem.value)) {
					heatLegend.valueAxis.showTooltipAt(ev.target.dataItem.value)
				}
				else {
					heatLegend.valueAxis.hideTooltip();
				}
			});

			polygonSeries.mapPolygons.template.events.on("out", function(ev) {
				if(!isNaN(ev.target.dataItem.value))
					heatLegend.valueAxis.hideTooltip();
			});
		},
		onChange(ev){
			console.log(ev.target.value);
			// var cpdata = [{
			// "id": "BD-A",
			// "name": "Barisal",
			// "value": 6
			// // "fill": am4core.color("#ff8000")
			// }, {
			// 	"id": "BD-B",
			// 	"name": "Chattogram",
			// 	"value": 5
			// 	// "fill": am4core.color("#ff6a00")
			// }, {
			// 	"id": "BD-C",
			// 	"name": "Dhaka",
			// 	"value": 4
			// 	// "fill": am4core.color("#ff1100")
			// }, {
			// 	"id": "BD-D",
			// 	"name": "Khulna",
			// 	"value": 3
			// 	// "fill": am4core.color("#ff8000")
			// }, {
			// 	"id": "BD-E",
			// 	"name": "Rajshahi",
			// 	"value": 2
			// 	// "fill": am4core.color("#ff4400")
			// }, {
			// 	"id": "BD-F",
			// 	"name": "Rangpur",
			// 	"value": 1
			// 	// "fill": am4core.color("#ffbb00")
			// }, {
			// 	"id": "BD-G",
			// 	"name": "Shylet",
			// 	"value": 2
			// 	// "fill": am4core.color("#ffbb00")
			// }, {
			// 	"id": "BD-H",
			// 	"name": "Mymensingh",
			// 	"value": 1
			// 	// "fill": am4core.color("#eaff00")
			// }];
			// this.crt_chart(this.pdata);
			console.log(this.pdata.value);
			this.getEventList();
		},
		getEventList(){
			//https://api.ohebsdd.org
			axios.get("https://api.ohebsdd.org/event_list",{
				params:{year: this.year}
			})
			.then((response) => {
				console.log(response);
				if(response.data.data == null){
					this.alerts = "No List to populate";
				}else{
					this.clearValue();
					// console.log(response.data.data);
					this.all_data = response.data.data; 
					var rec_data = response.data.data;
					rec_data.forEach((rd)=>{
						
						if(!this.year_list.includes(new Date(rd.notification_date).getFullYear()))
							this.year_list.push(new Date(rd.notification_date).getFullYear());
						var divs = rd.s_div.split(",");
						this.pdata.forEach((pd)=>{
							divs.forEach((div)=>{
								if(pd.name == div){
									console.log("Name matched");
									pd.value++;
								}
							})
						});
					});
					this.crt_chart(this.pdata);
					if(this.recent_notifications.length==0){
						var not_counter = 0;	//notification counter
						//for(var i=response.data.data.length-1;i>=0;i--){
						for(var i=0;i<response.data.data.length;i++){
							console.log("index "+i);
							this.recent_notifications.push(response.data.data[i]);
							if(not_counter++>3){
								break;
							}
						}
					}
					//console.log("recent_notifications");
					//console.log(this.recent_notifications);
				}

			});
		},
		clearValue(){
			this.pdata.forEach((pd)=>{
					pd.value=0;
			});
		},
		changeformat(value){
			return moment(String(value)).format('DD/MM/YYYY');
		}
	}
}
</script>
<style scoped>
#chartdiv{
	height:500px;
}
</style>