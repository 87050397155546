<template>
	<h1>Reduction of Rabies Incidences through Dog Vaccination Alongside</h1>
	<div id="chart">
		<apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
	</div>
	<hr/>
	<h3>Yearly district wise rabies case distribution in animals</h3>
	<label class="form-control">Please select year
		<select v-model="year"  @change="getEventsYR">
			<option>2020</option>
			<option>2021</option>
		</select>
	</label>
	<img src="@/assets/loading.gif" width="200" v-if="is_loading">
	<DataTable :columns="columns" :rows="rows"/>
</template>
<script>
import VueApexCharts from 'vue3-apexcharts'
import DataTable from '@/components/DataTable'
import axios from 'axios'
export default{
	name:'Rabies',
	components: {
		DataTable,
		apexchart: VueApexCharts
    },
    data() {      
		return{
			year:2020,
			columns:[
				{
					label: 'Year',
					field: 'year'
				},
				{
					label: 'District',
					field: 'district'
				},
				{
					label: 'Case',
					field: 'frequency'
				}
			],
			rows:[],
			is_loading:false,
			series: [{
				name: 'Vaccinated Dog Count',
				type: 'column',
				data: []
			}, {
				name: 'ARV Dose Count',
				type: 'column',
				data: []
			}, {
				name: 'Rabies Patient Count',
				type: 'line',
				data: []
			}],
			chartOptions: {
				chart: {
					height: 350,
					type: 'line',
					stacked: false
				},
				stroke: {
					width: [0, 4,2]
				},
				title: {
					text: ''
				},
				dataLabels: {
					enabled: true,
					enabledOnSeries: [0,1,2]
				},
				xaxis: {
					type: 'category',
					categories: []
				},
				yaxis: [{
					seriesName: 'Dog_vaccination',
					axisTicks: {
						show: true,
					},
					axisBorder: {
						show: true,
						color: '#008FFB'
					},
					labels: {
						style: {
							colors: '#008FFB',
						}
					},
					title: {
						text: "Vaccinated Dog Count",
						style: {
							color: '#008FFB',
						}
					},
					tooltip: {
						enabled: true
					}
					},
					{
					seriesName: 'Human_vaccination',
					opposite: true,
					axisTicks: {
						show: true,
					},
					axisBorder: {
						show: true,
						color: '#00E396'
					},
					labels: {
						style: {
							colors: '#00E396',
						}
					},
					title: {
						text: "ARV Dose Count",
						style: {
							color: '#00E396',
						}
					},
				},
				{
					seriesName: 'Human_rabies_patient',
					opposite: true,
					axisTicks: {
						show: true,
					},
					axisBorder: {
						show: true,
						color: '#FEB019'
					},
					labels: {
						style: {
							colors: '#FEB019',
						},
					},
					title: {
						text: "Rabies Patient Count",
						style: {
							color: '#FEB019',
						}
					}
				}],
				tooltip: {
					fixed: {
						enabled: true,
						position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
						offsetY: 30,
						offsetX: 60
					},
				},
				legend: {
					horizontalAlign: 'left',
					offsetX: 40
				}
			}
		}
    },
    methods:{
		getEventsYR(){
			this.is_loading=true;
			axios.get("https://api.ohebsdd.org/rabies",{
				params:{yr: this.year}
			})
			.then((response) => {
				this.rows.splice(0);
				this.is_loading=false;
				if(response.data.data.length > 0){
					response.data.data.forEach((rd)=>{
						this.rows.push(rd);
					});
				}
			});
		}
	},
	mounted(){
		this.getEventsYR();
		axios.get("https://api.ohebsdd.org/rabies/vac_pt")
			.then((response) => {
				var dg_vc = [];
				var hu_vc = [];
				var hu_pt = [];
				var years = [];
				if(response.data.data.length > 0){
					response.data.data.forEach((rd)=>{
						dg_vc.push(rd.dog_vac);
						hu_vc.push(rd.human_vac);
						hu_pt.push(rd.human_pt);
						years.push(rd.year);
						// console.log("yr-"+rd.year);
					});

					this.series[0].data = dg_vc;
					this.series[1].data = hu_vc;
					this.series[2].data = hu_pt;
					// this.chartOptions.title.text="abcde";
					this.chartOptions = {
						xaxis: {
							type: 'category',
							categories: years
						}
					}
					

					// this.chartOptions.xaxis.categories = years;
					// console.log(years);
				}
			});	
	}
}
</script>