<template>
	<vue-good-table
		:columns="columns"
		:rows="rows"
		:line-numbers="true"
		:search-options="{
			enabled: true,
			trigger: 'enter',
			placeholder: 'Search this table'
		}"
		:pagination-options="{
			enabled: true,
			perPage: 10
		}"
	/>
</template>
<script>
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { VueGoodTable } from 'vue-good-table-next';
export default{
	name:'DataTable',
	components: {
		VueGoodTable
    },
    props:['columns','rows']
}
</script>