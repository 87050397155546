<template>
	<div>
		<h2>List of events</h2>
		<div class="row">
			<div class="float-right">
				<label>Search </label> (press enter to filter table accordingly)
				<input type="text" @keyup.enter="filtered($event)">
			</div>
		</div>
		<table class="table table-striped" >
			<thead>
				<tr>
					<th>Title</th>
					<th>Origin</th>
					<th>District</th>
					<th>Upazila</th>
					<th>Notification date</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="item in products" :key="item.id" @click="details(item)">
					<td>{{item.title}}</td>
					<td>{{item.origin}}</td>
					<td>{{item.s_dis}}</td>
					<td>{{item.s_upz}}</td>
					<td>{{ changeformat(item.notification_date) }}</td>
				</tr>
			</tbody>
</table>    
	</div>
</template>
 
<script>
// import 'jquery/dist/jquery.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import "datatables.net-dt/js/dataTables.dataTables"
// import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from 'axios';
// import $ from 'jquery'; 
import moment from 'moment'


export default {
  mounted(){
    axios
    .get(this.api+"internal_dash",{
		headers: {
			'Authorization': localStorage.token
		}
	})
    .then((response)=>
    {
      this.products = response.data.data;
      this.all_events = response.data.data;
      // this.products.push(response.data.data);
      // $('#datatable').DataTable();
    })
  },
  data: function() {
        return {
			api:"https://api.ohebsdd.org/",
			products:[],
			all_events:[]
        }
    },
    methods:{
		changeformat(value){
			return moment(String(value)).format('DD/MM/YYYY');
		},
		filtered(event){
			var search = event.target.value.toLowerCase();
			if(search==""){
				this.products = this.all_events;
			}else{
				this.products = this.all_events;
				this.products = this.products.filter((ps)=>{
					if (ps.title.toLowerCase().indexOf(search)>-1) return ps;
					else if (ps.origin.toLowerCase().indexOf(search)>-1) return ps;
					else if (ps.s_dis.toLowerCase().indexOf(search)>-1) return ps;
					else if (ps.s_upz.toLowerCase().indexOf(search)>-1) return ps;
					else if (ps.notification_date.toLowerCase().indexOf(search)>-1) return ps;
				})
			}
		},
		details(id){
			// console.log("Clicked: "+id);
			this.$emit('details',id);
		}
    }
}
</script>