<template>
	<Header counter=5 />
	<div class="privacy_policy">
		<div class="row">
			<div class="col-md-3">
				<img alt="Vue logo" src="@/assets/onehealth.png" style="max-width:50%" >
			</div>
			<div class="col-md-9">
				<h1>Data Privacy and Data Sharing Policy of One Health Event Based Surveillance System Dashboard</h1>
			</div>
		</div>
		<br>
		<div class="row">
			<h3>Purpose</h3>
			<p class="text-justify"> The purpose of this policy is to outline the guideline for data sharing and confidentiality for the One Health Event Based Surveillance Data Dashboard and One Health Secretariat Template used by officials works at institution in Bangladesh under One health umbrella. This app has been developed to help track and report probable outbreak diseases data in the field. As Such, we are committed to maintain the privacy and confidentiality of all data collected through this app. This policy outlines our practices for collecting, using and protecting the data collected through this dashboard and app.</p>
			<h3>Scope</h3>
			<p class="text-justify"> This policy applies to all users of the One Health Event Based Surveillance Data Dashboard and One Health Secretariat Template app.</p>
			<h3>Guidelines:</h3>
			<h3>Confidentiality</h3> 
			<p class="text-justify"> All data collected through the One Health Event Based Surveillance Data Dashboard and One Health Secretariat Template app must be treated as confidential and should not be shared anyone who is not authorized to receive it.</p>
			<h3>Authorized Access</h3>
			<p class="text-justify"> Access to raw data collected through the app will be restricted to authorized personnel only, who have a legitimate need to access such data.</p>
			<h3>Data use</h3>
			<p class="text-justify"> Data collected through the dashboard and app will be used for the purpose of reporting, analysis and management decisions regarding wildlife populations and disease outbreaks, and for no other purpose.</p>
			<h3>Data Sharing</h3>
			<p class="text-justify"> Data collected through the dashboard and app will be shared with One Health stakeholders subject to prior authorization and compliance with relevant laws and regulations.</p>
			<h3>Data Retention</h3> 
			<p class="text-justify"> Data collected through the app will be retained for as long as necessary for the purpose for which it is collected, and will be disposed of in accordance with relevant laws and regulations.</p>
			<h3>Data Security</h3> 
			<p class="text-justify"> The app will use appropriate security measures to protect data collected through the app from unauthorized access, theft, or loss. We use industry-standard encryption to protect data in transit and at reset. Access to the data limited to authorized personnel only.</p>
			<h3>User Responsibility</h3>
			<p class="text-justify"> It is the Responsibility of all users of the One Health Data Dashboard and Template  to comply with this data sharing policy and to report any breaches of this policy to the relevant authorities.</p>
			<h3>Change of Policy</h3> 
			<p class="text-justify"> One Health Secretariat reserve the right to change or modify the policy complying with National as well as international standard & regulations.</p>
			<h3>Contact information</h3>
			<p class="text-justify"> If you have any queries or questions regarding our data privacy and data sharing policy, please contact us <strong>contact.ohebsdd@gmail.com</strong></p>
			<h3>Conclusion</h3>
			<p class="text-justify"> The One Health Secretariat Template (Wildlife Disease Reporting Android App) is designed to support the effective management of wildlife disease outbreaks by enabling early warning system engaging field staff to report and monitor the spread of disease. This data sharing policy outlines the guidelines for the responsible use and sharing of data collected through the app, ensuring that data is used only for the intended purpose, and that confidentiality and data security are maintained at all times.</p>
			<hr/>
		</div>
	</div>
</template>
<script>
import Header from '@/components/Header'
export default {
	name: 'Dash',
	components:{
		Header
	}
}
</script>