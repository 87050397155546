<template>
	<apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
</template>
<script>
import VueApexCharts from 'vue3-apexcharts'
export default{
	name:'SubWeek',
	components: {
      apexchart: VueApexCharts,
    },
    props:['data'],
    data() {
		return{
			series: [{
				data:[0,0,0,0,0,0,0,0]
			}],
			chartOptions: {
				chart: {
					type: 'bar',
					height: 350,
					
					toolbar: {
						show: true
					},
					zoom: {
						enabled: true
					}
				},
				responsive: [{
					breakpoint: 480,
					options: {
						legend: {
							position: 'bottom',
							offsetX: -10,
							offsetY: 0
						}
					}
				}],
				plotOptions: {
					bar: {
						horizontal: false,
						borderRadius: 10
					}
				},
				xaxis: {
					type: 'Division',
					categories: ['Dhaka', 'Sylhet', 'Rangpur', 'Rajshahi','Mymensingh', 'Khulna', 'Barishal', 'Chattogram']
				},
				legend: {
					position: 'right',
					offsetY: 40
				},
				fill: {
					opacity: 1
				}
			}
		}
    },
    methods:{
		re_draw(data){
			this.series[0].data=[0,0,0,0,0,0,0,0];
			console.log("re_draw called!");
			data.forEach((item)=>{
				if(item.division=="Dhaka")
					this.series[0].data[0]++;
				else if(item.division=="Sylhet")
					this.series[0].data[1]++;
				else if(item.division=="Rangpur")
					this.series[0].data[2]++;
				else if(item.division=="Rajshahi")
					this.series[0].data[3]++;
				else if(item.division=="Mymensingh")
					this.series[0].data[4]++;
				else if(item.division=="Khulna")
					this.series[0].data[5]++;
				else if(item.division=="Barishal")
					this.series[0].data[6]++;
				else if(item.division=="Chattogram")
					this.series[0].data[7]++;
			});
		}
    }
}
</script>