<template>
	<div class="row">
		<div class="col-md-4"></div>
		<div class="col-md-4">
			<a href="http://dashboard.dghs.gov.bd/webportal/pages/controlroom_dengue_dashboard.php" target="blank" class="btn btn-block btn-primary">Dengue situation live data from DGHS dashboard</a>
		</div>
		<div class="col-md-4"></div>
	</div>
	<br/>
	<br/>
	<div class="row">
		<img src="https://resources.ohebsdd.org/dengue/reporting_form/dengue_reporting_form.jpg">
	</div>
	<br/>
	<br/>
	<div class="row">
		<img src="https://resources.ohebsdd.org/dengue/case_form/dengue_case_form.jpg">
	</div>
	<br/>
	<br/>
	<div class="row">
		<img src="https://resources.ohebsdd.org/dengue/death_form/dengue_death_form.jpg">
	</div>
	<br/>
	<br/>
	<div class="row">
		<img src="https://resources.ohebsdd.org/dengue/map/dengue_map.jpg">
	</div>
	<br/>
	<br/>
	<div class="row">
		<img src="https://resources.ohebsdd.org/dengue/serotype/dengue_serotype.jpg">
	</div>
	<br/>
	<br/>
	<div class="row">
		<img src="https://resources.ohebsdd.org//dengue/daily_form/dengue_daily_form.jpg">
	</div>
</template>
<script>
export default{
	name:'Dengue',
}
</script>