<template>
	<br/>
	<br/>
	<div class="row">
		<img src="https://resources.ohebsdd.org/cholera/report/report.jpg">
	</div>
	<br/>
	<br/>
	<div class="row">
		<img src="https://resources.ohebsdd.org/cholera/sitewise/sitewise.jpg">
	</div>
	<br/>
	<br/>
	<div class="row">
		<img src="https://resources.ohebsdd.org/cholera/monthwise/monthwise.jpg">
	</div>
</template>
<script>
export default{
	name:'Cholera',
}
</script>