<template>
	<h3>{{outbreak.title}}</h3>
	<div class="row">
		<div class="col-md-6">
			<h4>Time measures</h4>
			<p>Notification date: {{changeformat(outbreak.notification_date)}}</p>
			<p>Verification start date: {{changeformat(outbreak.veri_start)}}</p>
			<p>Verification end date: {{changeformat(outbreak.veri_end)}}</p>
			<p>Investigation start date: {{changeformat(outbreak.inv_st_date)}}</p>
			<p>Investigation end date: {{changeformat(outbreak.investigation_end)}}</p>

		</div>
		<div class="col-md-6">
			<h4>Location</h4>
			<p>Division: {{outbreak.s_div}}</p>
			<p>District: {{outbreak.s_dis}}</p>
			<p>Upazila: {{outbreak.s_upz}}</p>
			<p>Area: {{outbreak.adds}}</p>
			
		</div>
	</div>
	<p><b>Information source:</b>{{outbreak.inf_src}}</p>
	<p><b>Verified from:</b> {{outbreak.c_name}} ({{outbreak.c_num}})</p>
	<p><b>Verification mode:</b> {{outbreak.veri_mod}}</p>
	<p><b>Event shared organization:</b> {{team_format(outbreak.ev_team)}}</p>
	<p><b>Investigation by:</b> {{team_format(outbreak.res_ins)}}</p>
	<p><b>Outbreak origin:</b> {{outbreak.origin}}</p>
	
	<h4>Outbreak highlights</h4>
	<table class="table">
		<thead>
			<th>Human</th>
			<th>Animal</th>
		</thead>
		<tbody>
			<tr>
					<td></td>
					<td>
						<label>Type</label>
						<p> {{outbreak.an_type}}</p>
					</td>
				</tr>
			<tr>
				<td>
					<label>confirmed disease / provisional diagnosis</label>
					<p> {{outbreak.hu_con_dis}}</p>
				</td>
				<td>
					<label>confirmed disease / provisional diagnosis</label>
					<p> {{outbreak.an_con_dis}}</p>
				</td>
			</tr>
			<tr>
				<td>
					<label>Number of confirmed cases</label>
					<p> {{outbreak.hu_sus_case}}</p>
				</td>
				<td>
					<label>Number of confirmed cases</label>
					<p> {{outbreak.an_sus_case}}</p>
				</td>
			</tr>
			<tr>
				<td>
					<label>Number of lab confirmed cases</label>
					<p> {{outbreak.hu_lab_case}}</p>
				</td>
				<td>
					<label>Number of lab confirmed cases</label>
					<p> {{outbreak.an_lab_case}}</p>
				</td>
			</tr>
			<tr>
				<td>
					<label>Number of mortality</label>
					<p> {{outbreak.hu_mortal}}</p>
				</td>
				<td>
					<label>Number of mortality</label>
					<p> {{outbreak.an_mortal}}</p>
				</td>
			</tr>
			<tr>
				<td>
					<label>Source of infection</label>
					<p> {{outbreak.hu_src_infec}}</p>
				</td>
				<td>
					<label>Source of infection </label>
					<p> {{outbreak.an_src_infec}}</p>
				</td>
			</tr>
		</tbody>
	</table>
	<p><b>Event details:</b> {{outbreak.ev_des}}</p>
	<p><b>Short intervention:</b> {{outbreak.short_inter}}</p>
	<p><b>Long intervention:</b> {{outbreak.long_inter}}</p>
	<p><b>Outcome:</b> {{outbreak.outcome}}</p>
	<p><b>Recommendation:</b> {{outbreak.recommendation}}</p>
	<p><b>Limitation:</b> {{outbreak.limitation}}</p>
	<p><b>Full report: </b> 
		<span v-for="rf in rpt_files" :key="rf">
			<a v-bind:href="'https://api.ohebsdd.org' + rf" target="blank" v-if="rf"> file {{rf.id}} </a><br/>
		</span>
	</p>
	<!-- <p>{{outbreak}}</p> -->
</template>
<script>
import axios from 'axios'
import moment from 'moment'

export default{
	name:'EventDetails',
	props:[
		'event'
	],
	data(){
		return{
			is_loading: false,
			id:'',
			api:"https://api.ohebsdd.org/internal_dash/",
			outbreak:{},
			inst_list:[],
			rpt_files:[]
		}
	},
	methods:{
		changeformat(value){
			return moment(String(value)).format('DD/MM/YYYY');
		},
		team_format(value){
			value = String(value);
			console.log(value.split(","));
			if (value === "") {
				return "";
			}
			var t_arr = value.split(",");
			var ts = "";
			t_arr.forEach((content)=>{
				if (ts != "") {ts+=", ";}
				console.log(this.inst_list);
				this.inst_list.forEach((ins)=>{
					if(content == ins.id)
						ts += ins.name;
				});
			});
			return ts;
		},
		rpt_files_format(value){
			value = String(value);
			console.log(value.split(","));
			if (value === "") {
				this.rpt_files = [];
				return;
			}
			var t_arr = value.split(",");
			t_arr.forEach((content)=>{
				this.rpt_files.push(content);
			});
		}
	},
	mounted(){
		this.is_loading=true;
		this.id = this.event.id;
		axios
		.get(this.api+this.event.id,{
			headers: {
			'Authorization': localStorage.token
			}
		})
		.then((response)=>{
			this.is_loading=false;
			this.outbreak = response.data.data[0];
			this.rpt_files_format(this.outbreak.evt_file);
			console.log(this.outbreak);
		})
		.catch((err)=>{
			this.is_loading=false;
			console.log(err);
		})
		axios.get('https://api.ohebsdd.org/getins')
		.then((response) => {
			this.is_loading=false;
			console.log(response);
			if(response.status == 500){
				this.alerts="response";
			}else if(response.status == 200){
				if(!response.data.data){
					this.alerts=response.data.msg;
				}else{
					this.inst_list = response.data.data;	
				}
				
			}
		})
	}
}	
</script>