<template>
	<nav class="navbar navbar-default">
		<div class="container-fluid">
			<div class="navbar-header">
				<a class="navbar-brand" href="https://ohebsdd.org/dash">OHEBSDD</a>
			</div>
			<ul class="nav navbar-nav">
				<li><a href="http://www.iedcr.gov.bd/site/page/9a0d1d63-a7ac-4607-ac80-7d046f8fc7f8/" target="_blank">Home</a></li>
				<li :class="{'active' :counter==1}"><router-link to="/dash">Dashboard</router-link></li>
				<li :class="{'active' :counter==2}" v-if="counter<=2"><router-link to="/">login</router-link></li>
				<li :class="{'active' :counter==3}" v-if="counter==3"><router-link to="/">Admin panel</router-link></li>
				<li><a href="https://resources.ohebsdd.org/template/" target="_blank">template</a></li>
				<li :class="{'active' :counter==5}"><router-link to="/privacy_policy">Privacy policy</router-link></li>
			</ul>
			<ul class="nav navbar-nav navbar-right" v-if="counter==3">
				<!-- <li><a href="#"><span class="glyphicon glyphicon-user"></span> Sign Up</a></li> -->
				<li><router-link to="/" @click='navto(0)'><span class="glyphicon glyphicon-log-in"></span> Logout</router-link></li>
			</ul>
		</div>
	</nav>
</template>
<script>
export default{
	name:'Header',
	props:{
		counter: Number
	},
	methods:{
		navto(index){
			if(index === 0){
				localStorage.removeItem('data');
				localStorage.removeItem('token');
				localStorage.removeItem('id');
				localStorage.removeItem('name');
				this.$router.push ({ name: 'Home' })
			}
			this.nav_index=index;
		}
	}
}
</script>