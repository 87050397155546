<template>
	<h3>List of complains</h3>
	<p>Click on the row to see details</p>
	<table class="table table-striped">
		<thead>
			<tr>
				<th v-if="dept>1">Affected Species</th>
				<th v-if="dept==1">Suspected Disease</th>
				<th>Place</th>
				<th v-if="dept>1">Animal risk at</th>
				<th v-if="dept==1">Number sick</th>
				<th>Details</th>
				<th>Notification date</th>
				<th>Status</th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="event in all_events" :key="event.id" @click="details(event)">
				<td v-if="dept>1">{{event.cname_spec_anim}}</td>
				<td v-if="dept==1">{{event.name_pos_disease}}</td>
				<td>{{event.district}}, {{event.thana_or_upazilla}}</td>
				<td v-if="dept>1">{{event.no_of_risk_wl_anim}}</td>
				<td v-if="dept==1">{{event.total_sick_patients}}</td>
				<td>{{event.desc_of_event}}</td>
				<td>{{ changeformat(event.date_of_event) }}</td>
				<td>
					<p class="text-success" v-if="event.ackn==1">Acknoledged</p>
					<p class="text-warning" v-if="event.ackn==0">Not Acknoledged</p>
				</td>
			</tr>
		</tbody>
	</table> 
</template>
<script>
import axios from 'axios';
import moment from 'moment'
export default {
	name:'BFDTemplateList',
	components: {
		
    },
	mounted(){
		var url = this.api+"bfd";
		if(localStorage.institution==1){
			url = this.api+"humantmp";
		}
		else if(localStorage.institution==2){
			url = this.api+"dlstmp";
		}
		else if(localStorage.institution==3){
			url = this.api+"bfd";
		}
		this.dept = localStorage.institution;
		axios
			.get(url,{
				headers: {
					'Authorization': localStorage.token
				}
			})
			.then((response)=>
			{
				this.all_events = response.data.data;
			})
	},
	data: function() {
		return {
			api:"https://api.ohebsdd.org/",
			dept:0,
			all_events:[]
		}
	},
	methods:{
		changeformat(value){
			return moment(String(value)).format('DD/MM/YYYY');
		},
		details(event){
			console.log("Clicked: "+event.id);
			this.$emit('details',event);
		}
	}
}
</script>