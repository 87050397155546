<template>
	<h3>Description of the complaint</h3>
	<p>This complaint is address to 
		<strong v-if="event.types==2">public health</strong>
		<strong v-if="event.types==3">animal health</strong>
		<strong v-if="event.types==1">wild life and environment</strong>
	</p>

	<table class="table">
		<tbody>
			<tr>
				<td>Information Uploaded by</td>
				<td>
					{{event.name}} ({{event.age}})<br/>
					{{event.mobile}}<br/>
					{{event.desg_or_prof}}
				</td>
			</tr>
			<tr>
				<td>Information occured place</td>
				<td>
					District: {{event.district}}<br/>
					Thana or upazila: {{event.thana_or_upazilla}}<br/>
					Address: {{event.unions}}<br/>
					location: {{event.gps_lat}},{{event.gps_lng}}
				</td>
			</tr>
			<tr v-if="event.types==1">
				<td>Information Forest level</td>
				<td>
					Circle office: {{event.f_circle_level}}<br/>
					Divisional office: {{event.f_division_level}}<br/>
					Range office: {{event.range_ofc}}<br/>
					Beat office: {{event.beat_ofc}}
				</td>
			</tr>
			<tr>
				<td>Date of occurence:</td>
				<td>{{changeformat(event.date_of_event)}}</td>
			</tr>
			<tr>
				<td>Image (uploaded)</td>
				<td><img v-bind:src="'data:image/jpeg;base64,'+event.img_of_ss" alt="No image found" /></td>
			</tr>
			<tr>
				<td>Description of the Event</td>
				<td>{{event.desc_of_event}}</td>
			</tr>
			<tr v-if="event.types==2">
				<td>Affected community</td>
				<td>
					Affected people gender: 
					<p v-show="event.affected_person_m==1">Male </p>
					<p v-show="event.affected_person_f==1">Female </p>
					<p v-show="event.affected_person_t==1">Transgender </p>
					<br/>
					Age range of affected people: {{event.avg_age_range_ap}}<br/>
					Possible source: {{event.pos_source_disease}}<br/>
					Possible disease: {{event.name_pos_disease}}<br/>
					Total Number of sick prople: {{event.total_sick_patients}}<br/>
					Possibe contact with animal: {{event.pos_contact_animals}}
				</td>
			</tr>
			<tr v-if="event.types!=2">
				<td>Affected animal information</td>
				<td>
					Infected species: {{event.cname_spec_anim}}<br/>
					Number of sick: {{event.no_of_sick_wl_anim}}<br/>
					Number of dead: {{event.no_of_dead_wl_anim}}<br/>
					Number of risk: {{event.no_of_risk_wl_anim}}
				</td>
			</tr>
			<tr v-if="event.types==2">
				<td>Sign & symptoms</td>
				<td>
					<p v-show="event.cds_fev==1">Fever </p>
					<p v-show="event.cds_snz==1">Sneezing &amp; Coughing </p>
					<p v-show="event.cds_headache==1">Headache </p>
					<p v-show="event.cds_suffocation==1">Breathing difficulty </p>
					<p v-show="event.cds_run_nose==1">Runny nose </p>
					<p v-show="event.cds_nose_bld==1">Epistaxis/nose bleeding </p>
					<p v-show="event.cds_mood_swing==1">Mood swing </p>
					<p v-show="event.cds_delirium==1">Delirium </p>
					<p v-show="event.cds_cng_behave==1">Behavioral change </p>
					<p v-show="event.cds_voice_change==1">Voice change </p>
					<p v-show="event.cds_faint==1">Faint/Unconsciuos </p>
					<p v-show="event.cds_salivation==1">Dripping of saliva </p>
					<p v-show="event.cds_convulsion==1">Convulsion </p>
					<p v-show="event.cds_sore_throat==1">Sore throat </p>
					<p v-show="event.cds_cng_fhabit==1">Change in food habit </p>
					<p v-show="event.cds_discol_eye==1">Discoloration of eyeris </p>
					<p v-show="event.cds_vesicle==1">Vesicle/Blister formation </p>
					<p v-show="event.cds_abdom_pain==1">Abdominal pain </p>
					<p v-show="event.cds_weakness==1">Fatigue/weakness </p>
					<p v-show="event.cds_abdom_cramp==1">Abdominal cramp </p>
					<p v-show="event.cds_rash==1">Rash/Skin rash </p>
					<p v-show="event.cds_joint_pain==1">Joint pain </p>
					<p v-show="event.cds_red_eye==1">Red eye </p>
					<p v-show="event.cds_diarrhoea==1">Diarrhoea </p>
					<p v-show="event.cds_vomiting==1">Vomiting </p>
					<p v-show="event.cds_anxiety==1">Anxiety/Irritability </p>
					<p v-show="event.cds_food_poisoning==1">Food poisoning </p>
					{{event.cds_others}}
				</td>
			</tr>
			<tr v-if="event.types!=2">
				<td>Sign & symptoms</td>
				<td>
					<p v-show="event.cds_fev==1">Fever </p>
					<p v-show="event.cds_ab_behave==1">Abnormal behavior </p>
					<p v-show="event.cds_snz==1">Sneezing &amp; Coughing </p>
					<p v-show="event.cds_run_nose==1">Runny nose </p>
					<p v-show="event.cds_pface==1">Pale face </p>
					<p v-show="event.cds_nose_bld==1">Epistaxis </p>
					<p v-show="event.cds_fcolor==1">Fur discoloration </p>
					<p v-show="event.cds_alpoecia==1">Alopecia </p>
					<p v-show="event.cds_diz==1">Dizziness </p>
					<p v-show="event.cds_tail_dis==1">Tail dislocation </p>
					<p v-show="event.cds_cng_behave==1">Behavioral Change </p>
					<p v-show="event.cds_swel==1">Swelling/Edema/Anasarca </p>
					<p v-show="event.cds_cng_skn_col==1">Change skin color </p>
					<p v-show="event.cds_petechial==1">Petechial hemorrhage in feet </p>
					<p v-show="event.cds_hematoma==1">Hematoma in claw/ Subungual hematoma </p>
					<p v-show="event.cds_paralysis==1">Paralysis of wings </p>
					<p v-show="event.cds_bleeding==1">Bleeding through natural Orificis </p>
					<p v-show="event.cds_incordination==1">Head & body Inco-ordination </p>
					<p v-show="event.cds_salivation==1">Salivation </p>
					<p v-show="event.cds_suffocation==1">Suffocation </p>
					<p v-show="event.cds_discol_eye==1">Discoloration of eyeris </p>
					<p v-show="event.cds_diarrhoea==1">Diarrhoea </p>
					<p v-show="event.cds_cng_fhabit==1">Change in food habit </p>
					<p v-show="event.cds_hydrophobia==1">Hydrophobia </p>
					{{event.cds_others}}
				</td>
			</tr>
			<tr>
				<td>Action</td>
				<td>
					<button class="btn btn-success" v-if='event.ackn==0' v-show="action_btn==1" @click="do_ack(1)">Acknoledged</button>
					<button class="btn btn-warning" v-if='event.ackn==1' v-show="action_btn==1" @click="do_ack(0)">Not Acknoledged</button>
				</td>
			</tr>
		</tbody>
	</table>
</template>
<script>
import moment from 'moment'
import axios from 'axios';
export default{
	name:'TemplateDetails',
	props:[
		'event'
	],
	data(){
		return{
			action_btn:1,
			api:"https://api.ohebsdd.org/"
		}
	},
	methods:{
		changeformat(value){
			return moment(String(value)).format('DD/MM/YYYY');
		},
		do_ack(value){
			this.action_btn=0;
			if(this.event.types==1){
				this.api = this.api+"bfd/";
			}else if(this.event.types==2){
				this.api = this.api+"humantmp/";
			}else if(this.event.types==3){
				this.api = this.api+"dlstmp/";
			}
			this.api = this.api+this.event.id;

			axios
				.post(this.api,{
					'ackn': value
				})
				.then((response)=>
				{
					//this.all_events = response.data.data;
					if(response.data.success==1){
						//this.event.ackn=value;
						this.action_btn=0;
					}
				})
		}
	},
	mounted(){
		
	}
}	
</script>