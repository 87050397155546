<template>
	<div id="chart">
		<apexchart type="donut" 
		:options="chartOptions"
		:series="series">
		</apexchart>
	</div>
</template>
<script>
import VueApexCharts from 'vue3-apexcharts'
export default{
	name:'Donut_chart',
	props:[
		'series','values'
	],
	components: {
		apexchart: VueApexCharts
    },
    data() {      
		return{
			chartOptions: {
				chart: {
					type: 'donut',
				},
				plotOptions: {
					pie: {
						donut: {
							labels: {
								show: true,
								total: {
									showAlways: true,
									show: true
								}
							}
						}
					}
				},
				labels: this.values,
				responsive: [{
					breakpoint: 480,
					options: {
						chart: {
							width: 100
						},
						legend: {
							position: 'bottom'
						}
					}
				}]
			},
		}
    }
}
</script>