<template>
	<div class="row">
		<div class="col-md-6"><h3>Captured events list</h3></div>
		<div class="col-md-6 text-right"><button class="btn"><i class="fa fa-plus" aria-hidden="true" @click="$emit('newCapture')"> Add new</i></button></div>
	</div>
	<WrongAlert :alerts="alerts" />
	<table class="table">
		<thead c-if="captures.length>0">
			<th>Description</th>
			<th>Uploaded file</th>
			<th>Entry date</th>
			<th>Upazila</th>
			<th>Action</th>
		</thead>
		<tbody>
			<tr v-for="cap in captures" :key="cap.id">
				<td>{{cap.ev_des}}</td>
				<td><a v-bind:href="'https://api.ohebsdd.org' + cap.file_link" target="blank" v-if="cap.file_link">File</a></td>
				<td>{{ changeformat(cap.created_at) }}</td>
				<td>{{ cap.s_upz }}</td>
				<td v-if="cap.is_verified==0"><button class="btn" @click="cap_edit(cap)">Edit</button></td>
				<td v-if="cap.is_verified==1">Already Verified</td>
			</tr>
		</tbody>
	</table>
</template>
<script>
import WrongAlert from '../WrongAlert.vue';
import axios from 'axios'
import moment from 'moment'
export default{
	name: 'EventVerify',
	components: {WrongAlert},
	data(){
		return {
			api:"https://api.ohebsdd.org/",
			captures:[],
			alerts:null,
			options:{day: 'numeric', month: 'long', year: 'numeric'}
		}
	},
	methods:{
		syncCaptures(){
			this.alerts='';
			axios.get(this.api+"event_capture",{
				headers: {
					'Authorization': localStorage.token
				}
			})
			.then((response) => {
				if(response.data.data==null){
					this.alerts = response.data.msg;
				}
				else if(response.data.data.length == 0){
					this.alerts = "No List to populate";
				}else{
					this.captures = response.data.data;
				}

			})
		},
		changeformat(value){
			return moment(String(value)).format('DD/MM/YYYY');
		},
		cap_edit(value){
			this.$emit('capEdit',value);
		}
	},
	mounted(){
		this.syncCaptures();
	}
}
</script>