<template>

	<h3>Nipah virus surveillance</h3>
	<iframe src="https://www.google.com/maps/d/embed?mid=1WEEAMD0ecCHFkhb2Ro3ChCfW6uzwSbU&ehbc=2E312F" width="100%" height="500" ></iframe>

	<hr/>
	<h3>District wise NIPAH case distribution</h3>
	<DataTable
		:columns="columns"
		:rows="dis_case_distribution"
	/>
	<WrongAlert :alerts="alerts"/>
	<img src="@/assets/loading.gif" width="200" v-if="is_loading"> 
	<br/>
	<hr/>
	<h3>Identified infected cases and deaths from Nipah virus </h3>
	<div id="chart">
		<apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
	</div>
	<div class="row">
		<h2>Nipah death and confirmed cases by Year</h2>
		<DataTable :columns="yrnipahcase_heading" :rows="yrnipahcase_data"/>
		<WrongAlert :alerts="alerts_yr" />
	</div>
</template>
<script>
import VueApexCharts from 'vue3-apexcharts'
import WrongAlert from '@/components/WrongAlert'
import axios from 'axios'
import DataTable from '@/components/DataTable'
export default{
	name:'Nipah',
	components: {
		WrongAlert,
		apexchart: VueApexCharts,
		DataTable
    },
    data() {      
		return{
			is_loading:false,
			yrnipahcase_heading:[
				{
					label: 'Year',
					field: 'year'
				},
				{
					label: 'Cases',
					field: 'case_num'
				},
				{
					label: 'Death',
					field: 'death'
				},
				{
					label: 'Year wise Death rate in %',
					field: 'death_rate'
				}
			],
			yrnipahcase_data:[],
			dis_case_distribution:[],
			columns :[
				{
					label: 'District',
					field: 'district'
				},
				{
					label: 'Year',
					field: 'year'
				},
				{
					label: 'No of case',
					field: 'case_num'
				}
			],
			series: [{
				name: 'Identified case',
				type: 'column',
				data: [],
			}, {
				name: 'Death',
				type: 'line',
				data: [],
				color: '#ff1605'
			}],
			chartOptions: {
				chart: {
					height: 350,
					type: 'line',
				},
				stroke: {
					width: [0, 4]
				},
				title: {
					text: 'Traffic Sources'
				},
				dataLabels: {
					enabled: true,
					enabledOnSeries: [1]
				},
				yaxis: [{
					title: {
					text: 'Identified case',
					},
				}]
			},
			alerts_yr:'',
		}
    },
    mounted(){
		this.is_loading=true;
		axios.get("https://api.ohebsdd.org/nipah")
		.then((response) => {
			this.is_loading=false;
			if(response.data.data.length == 0){
				this.alerts = "No List to populate";
			}else{
				this.alerts = "";
				this.dis_case_distribution = response.data.data;
			}
		});
		axios.get("https://api.ohebsdd.org/nipah/yearwise")
		.then((response) => {
			this.is_loading=false;
			if(response.data.data.length == 0){
				this.alerts_yr = "No List to populate";
			}else{
				this.alerts_yr = "";
				this.yrnipahcase_data = response.data.data;
				var identified_case = [];
				var death = [];
				var years = [];
				response.data.data.forEach((row)=>{
					identified_case.push(row.case_num);
					death.push(row.death);
					years.push(row.year);
				});
				this.series[0].data = identified_case;
				this.series[1].data = death;
				this.chartOptions = {
					xaxis: {
						type: 'category',
						categories: years
					}
				}
			}
		});
    }
}
</script>