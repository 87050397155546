<template>
	<div class="form-group">
		<label style="vertical-align: middle">
            <input type="radio" name="arc_events_visual" value="1" @change="arc_events_visual_alter($event)" :checked="arc_events_visual_value==1" /> Data-table
        </label>
        <label style="vertical-align: middle">
            <input type="radio" name="arc_events_visual" value="2"  @change="arc_events_visual_alter($event)" :checked="arc_events_visual_value==2" /> Map plot
        </label>
	</div>
	<br/>
	<div v-if="arc_events_visual_value==1">
		<label class="form-control">Please select year
			<select v-model="year"  @change="getEventsYR">
				<option>2007</option>
				<option>2008</option>
				<option>2009</option>
				<option>2010</option>
				<option>2011</option>
				<option>2012</option>
				<option>2013</option>
				<option>2014</option>
				<option>2015</option>
				<option>2016</option>
				<option>2017</option>
				<option>2018</option>
				<option>2019</option>
				<option>2020</option>
				<option>2021</option>
				<option>2022</option>
			</select>
		</label>
		<br/>
		<SubWeek :data="event_list" ref="sub_week"/>
		<br/>
		<img src="@/assets/loading.gif" width="200" v-if="is_loading"> <br/>
		<br/>
		<vue-good-table
			:columns="columns"
			:rows="rows"
			:line-numbers="true"
			:search-options="{
				enabled: true,
				trigger: 'enter',
				placeholder: 'Search this table'
			}"
			:pagination-options="{
				enabled: true,
				perPage: 10
			}"
		/>
	</div>
	<div v-if="arc_events_visual_value==2">
		<iframe src="https://www.google.com/maps/d/embed?mid=1sNsCEeX8aubVtErMh-8AbVfzg1vmifw&ehbc=2E312F" width="100%" height="500"></iframe>
	</div>
</template>
<script>
import moment from 'moment'
import axios from 'axios'
import SubWeek from '@/components/SubWeek.vue'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { VueGoodTable } from 'vue-good-table-next'
export default{
	name:'ArchievedEvents',
	components:{
		SubWeek,
		VueGoodTable
	},
	data(){
		return{
			event_list:[],
			year:2022,
			is_loading:false,
			arc_events_visual_value:1,
			columns :[
				{
					label: 'Incident Name',
					field: 'incident_name'
				},
				{
					label: 'Division',
					field: 'division'
				},
				{
					label: 'District',
					field: 'dis'
				},
				{
					label: 'Upazila',
					field: 'upz'
				},
				{
					label: 'Area',
					field: 'area'
				},
				{
					label: 'Investigation Date',
					field: 'investigation_date',
					type: 'date',
					dateInputFormat: 'dd/MM/yyyy',
					dateOutputFormat: 'dd/MM/yyyy'
				}
			],
			rows:[]
		}
	},
	methods:{
		changeformat(value){
			return moment(String(value)).format('DD/MM/YYYY');
		},
		getEventsYR(){
			this.is_loading=true;
			//https://api.ohebsdd.org
			axios.get("https://api.ohebsdd.org/arc_events",{
				params:{year: this.year}
			})
			.then((response) => {
				this.is_loading=false;
				if(response.data.data == null){
					this.alerts = "No List to populate";
				}else{
					this.event_list=[];
					this.rows=[];
					// console.log(response.data.data);
					this.event_list = response.data.data; 
					this.$refs.sub_week.re_draw(this.event_list);
					this.event_list.forEach((event)=>{
						event.investigation_date = this.changeformat(event.investigation_date);
						this.rows.push(event);
					});
				}
			});
		},
		arc_events_visual_alter(event){
			this.arc_events_visual_value = event.target.value;
		}
	},
	mounted(){
		this.getEventsYR();
	}
}
</script>